.MentionsInputBox {
  position: relative;

  .MentionsInput {
    .MentionsInput__control {
      background-color: white;
      border-radius: 33px;
    }

    .MentionsInput__highlighter,
    .MentionsInput__input {
      margin: 0 !important;
      padding: 5px 10px;
      border: 1px solid var(--color-gray-light);
      box-shadow: none;
      font-size: 14px;
      min-height: 35px;
      border-radius: 33px;
      overflow: hidden;
      word-break: break-word!important;
    }

    .MentionsInput__highlighter {

      strong {
        color: transparent;
        background-color: var(--color-orange-light);
        border-radius: 5px;
      }
    }

    .MentionsInput__suggestions {
      top: auto !important;
      bottom: 110%;
      z-index: 100 !important;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      width: 100%;
      left: auto;
      right: auto !important;
      overflow: hidden;

      @media screen and (max-width: 40em) {
        left: -20px;
        width: calc(100% + 40px);
      }

      .MentionsInput__suggestions__list {
        border: 1px solid var(--color-gray-light);
        border-radius: 10px;
        max-height: 200px;
        overflow-y: auto;

        .MentionsInput__suggestions__item {
          margin: 0;
          padding: 5px 10px;
          font-size: 14px;
          line-height: 20px;

          .user-suggestion-row {
            display: flex;
            align-items: center;
          }

          .user-avatar, .Avatar {
            margin-right: 10px;
          }

          .icon {
            display: inline-block;
            width: 25px;
            text-align: center;
            font-size: 22px;
            color: var(--color-black);
            margin-right: 10px;
          }

          .muted {
            color: var(--color-gray-dark);
            font-size: 12px;
          }

          &.MentionsInput__suggestions__item--focused {
            background-color: var(--color-blue-light);
          }
        }
      }
    }
  }

  &.is-edit-comment {
    .MentionsInput {
      .MentionsInput__input {
        font-style: italic;
        text-align: justify;
        transition:
          padding .25s,
          border-color .25s,
          border-width .25s,
          background-color .25s,
          min-height .25s;
        outline: none;
        box-shadow: none;
        color: var(--color-black);
        -webkit-text-fill-color: var(--color-black);
        padding: 5px 25px 5px 10px;
        background: var(--color-yellow-light) !important;
        resize: vertical;
        pointer-events: auto;
      }
    }

    &.is-square-input {
      .MentionsInput {
        .MentionsInput__control {
          background-color: var(--color-yellow-light);
          border-radius: 5px;
          border: none;
        }

        .MentionsInput__input,
        .MentionsInput__highlighter {
          border: none;
          background-color: transparent !important;
          font-style: italic;
          overflow: scroll !important;
          min-height: auto;
        }
      }
    }
  }

  &.is-square-input {
    .MentionsInput {
      .MentionsInput__input,
      .MentionsInput__highlighter {
        word-break: break-all;
        border-radius: 5px;
        min-height: 90px;
        max-height: 150px;
        overflow: scroll !important;
      }
    }
  }

  .open-mentions-btn {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 4px 0;
    font-size: 18px;
    cursor: pointer;
    color: var(--color-gray-dark);
    transition: color .4s;

    &:hover {
      color: var(--color-primary);
    }
  }

  &.isIOS {
    .MentionsInput {
      .MentionsInput__highlighter {
        font-size: 16px;
      }
    }

    .open-mentions-btn {
      font-size: 20px;
    }
  }
}
