@import '../../styles/variables';

.info-tooltip {

  .tooltip-icon {
    color: $primary;
    font-size: 18px;

    &:hover {
      cursor: pointer;
    }
  }
}

.tooltip-message {

  .rc-tooltip-content {
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;

    .rc-tooltip-inner {
      background-color: $light-gray;
      color: $black;
      max-width: 290px;
      opacity: 1;
    }

    .rc-tooltip-arrow {
      border-width: 10px 10px 0;
      border-top: 10px solid $light-gray;
      bottom: -1px;
      margin-left: -10px;
    }
  }
}
