.treatment-protocol-form {
  font-size: 14px;

  .protocol-comment {
    padding: 0 15px;
    max-width: 760px;
    margin-bottom: 15px;

    @media screen and (max-width: 63em) {
      max-width: unset;
    }
  }

  .action-btn-group {
    display: flex;
    align-items: center;

    @media screen and (max-width: 63em) {
      flex-direction: column;
      align-items: unset;
    }

    button {
      margin-bottom: 10px;
    }

    .clear-btn {
      margin-left: 20px;

      @media screen and (max-width: 63em) {
        margin-left: 0;
      }
    }

    .submit-btn {
      margin-left: auto;

      @media screen and (max-width: 63em) {
          margin-left: 0;
      }
    }
  }

  .apply-section {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;

    .radio-group {
      align-self: flex-start;
      width: 100%;
      max-width: 400px;
    }

    .label-description {
      color: var(  --color-gray-nice);
    }

    .multi-select {
      width: 100%;
      max-width: 720px;
    }
  }
}
