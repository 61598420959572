@import "../../../../styles/variables";

.toggleTitle {
  font-size: 14px;
}

.primary {
  color: $primary;
}

.toggleSub {
  margin: 0;
  color: $nice-grey;
}

.settings-multi-select {
  max-width: 780px;

  &.disabled {
    position: relative;

    &::after {
      cursor: not-allowed;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(white, .3);
    }
  }
}

.description {
  font-size: 14px;
  color: var(--color-gray-nice);
  font-weight: normal;
}

.time-select {
  max-width: 290px;
}
