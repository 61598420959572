@import "../../../styles/variables";

.MediaUploader {
  border-radius: 5px;
  color: #303030;
  font-size: 0.75rem;
  background-color: white;
  overflow: hidden;
  position: relative;

  .record-audio-button {
    font-size: 24px;
    padding: 6px 8px;
  }

  .uploader-header {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
    padding: 7px 10px;
    overflow: hidden;
    border: 1px solid #E8E8E8;
    border-bottom: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    .segment-title {
      margin-left: 10px;
      font-weight: 600;
      line-height: 35px;

      span {
        font-weight: normal;
      }
    }
  }

  .uploader-body {
    position: relative;
    background-color: $background;
    border: 2px dashed #E8E8E8;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: border-color .3s ease;

    &.dragOver {
      border-color: $primary;
    }

    @media screen and (max-width: 63.9375em) {
      &.empty {
        display: none;
      }
    }

    .assets-list {

      .asset-wrapper {
        border-bottom: 1px solid $light-gray;

        &:last-child {

          .assets-item,
          .asset-note {
            border-bottom: none;
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }

      .assets-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 10px;

        .asset-preview {
          position: relative;
          margin-right: 5px;

          .image {
            width: 70px;
            height: 45px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid $light-gray;
            padding: 1px;
            cursor: pointer;

            &.loading {
              background-size: contain;
            }

            &:hover {
              opacity: .75;
            }
          }
        }

        .asset-info {
          flex-grow: 1;
          min-width: 0;
          margin-right: 5px;
          align-self: flex-start;

          .asset-name {
            font-size: 13px;
            font-weight: 600;
            color: $primary;
            min-width: 0;

            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 2px;
          }

          .asset-description {
            font-size: 11px;
            font-weight: normal;
            color: $almost-black;
            align-self: flex-start;
          }

          .upload-progress {
            background-color: $medium-gray;
            height: 5px;
            width: 100%;
            margin: 5px auto;
            position: relative;

            .progress-value {
              background-color: $primary;
              height: 5px;
              width: 0;
              transition: width .4s ease;
            }
          }

          .uploading-asset-info {
            display: flex;
            flex-direction: row;
            flex: 1 auto;

            .asset-name {
              flex-grow: 1;
              min-width: 0;
              color: $black;

              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .asset-size {
              flex: 0 0 auto;
              margin-left: 5px;
              min-width: 0;
            }
          }
        }

        .asset-actions {
          display: flex;
          align-self: center;

          i {
            font-size: 22px;
            padding: 6px 8px;
            border-radius: 5px;
            cursor: pointer;
            color: $almost-black;

            &:hover {
              background: rgba(0, 0, 0, 0.1);
            }
          }
        }
      }

      .asset-note {
        width: 100%;
        color: $almost-black;
        font-size: 14px;

        .title {
          font-weight: 600;
          border-top: 1px solid $light-gray;
          border-bottom: 1px solid $light-gray;
          padding: 4px 10px;
        }

        .description {
          font-style: italic;
          padding: 6px 10px;
          background-color: $white;
          color: $light-black;
          word-wrap: break-word;
          white-space: pre-wrap;
        }
      }
    }

    .dropzone {
      position: absolute;
      opacity: .5;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
      background-color: #FFF;
      color: #000;
      min-height: 0;
      font-size: 13px;

      &.active {
        z-index: 1;
      }
    }

    .dropzone-placeholder {
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      color: $dark-gray;
      margin: 80px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all .3s ease;

      i {
        font-size: 48px;
        margin-bottom: 15px;
        color: $medium-gray;
        display: block;
      }

      a {
        text-decoration: underline;
        color: $primary;
      }

      &.minimized {
        margin: 0 auto;
        min-height: 50px;
        flex-direction: row;
        border-bottom: 1px solid $light-gray;

        i {
          font-size: 32px;
          margin-bottom: 0;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
  }

  .uploader-footer {
    display: flex;
    flex: 1 1 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
    border-top: 1px solid $light-gray;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button {
      height: 40px;
      width: 100%;
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 24px;
      border-radius: 0;
      border-top: none;

      &.hide {
        position: absolute;
        visibility: hidden;
      }

      &:not(:last-child) {
        border-right: none;
      }

      .fa.fa-mic {
        font-size: 28px;
      }
    }
  }

  .camera-input {
    display: none;
  }

  .disabled-uploader-overlay {
    background-color: rgba(100, 100, 100, .75);
    color: $white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    padding: 20px;
  }
}