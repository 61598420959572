.sort-dropdown {
  justify-content: right;
  display: flex;
  align-items: center;
  padding: 10px;
  color: var(--color-gray-dark);
  cursor: pointer;
  position: relative;
  font-size: 13px;

  @media screen and (max-width: 63.9375em) {
    font-size: 14px;
    margin: 5px 0px;
    padding: 5px;
  }

  .angle-icon {
    padding-left: 5px;
    font-size: inherit;
  }

  .sort-dropdown-menu {
    position: absolute;
    z-index: 1;
    top: 100%;
    margin-top: 10px;
    left: auto;
    right: 0;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.3) 0 -1px 12px 0;
    background-color: white;
    overflow-y: auto;
    max-height: 320px;
    min-width: 240px;
    min-height: 40px;

    @media screen and (max-width: 63.9375em) {
      left: 0;
      right: auto;
    }

    .menu-item {
      padding: 10px;
      min-height: 40px;
      background-color: white;
      display: flex;
      align-items: center;
      transition: background-color 0.3s, color 0.3s ease;
      border-bottom: 1px solid var(--color-gray-light);
      color: var(--color-black);
      font-weight: 400;
      white-space: nowrap;

      .item-label {
        flex: 1;
      }

      .direction-icon {
        margin-right: 10px;
        color: var(--color-gray-dark);
      }

      &:hover {
        background-color: var(--color-orange-light);
        color: var(--color-primary);

        .selected-icon {
          opacity: 0;
          transform: translateX(-10px);
        }

        .remove-icon {
          opacity: 1;
          visibility: visible;
        }
      }

      &.active {
        color: var(--color-primary);
      }

      &:last-child {
        border-bottom: none;
      }

      .selected-icon {
        opacity: 1;
        transition: opacity 0.5s, transform 0.3s ease-in-out;
        transform: translateX(0px);
      }

      .option-icon,
      .selected-icon {
        font-size: 15px;
      }
    }
  }
}

.hidden {
  visibility: hidden;
}