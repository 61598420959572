.report-template-form {
  font-size: 14px;
  margin-bottom: 20px;

  .ff-trigger {
    display: flex;
    justify-content: space-between;

    .radio-group {
      min-width: 350px;
    }
  }

  .form-footer {
    z-index: 2;
    bottom: 0;
    position: sticky;
    background-color: white;

    .btn-group {
      padding: 10px 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .btn {
        min-width: 150px;
      }
    }
  }

  .categories-container {
    .categories-container-header {
      display: flex;
      align-items: center;

      .create-category-btn {
        min-width: 160px;
      }

      .search-box {
        height: 40px;
        max-width: unset;
        width: 100%;
      }
    }
  }
}
