.pdf-preview {
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:hover {
        .pdf-image {
            transform: scale(1.08);
        }
    }

    .pdf-asset-icon {
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 52px;
        color: var(--color-red-brand);
    }

    .pdf-image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        transition: .3s;
    }
}