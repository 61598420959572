@import "styles/variables";

.report-template-row {
  font-size: 14px;
  padding: 10px;
  border: 1px solid var(--color-gray-light);
  min-height: 60px;
  background-color: $background;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  .reports-count-section {
    flex: 0 0 20%;
    font-weight: 600;
    padding: 0 20px;

    @media screen and (max-width: 63.9375em) {
      font-size: 13px;
      flex: 1 0 20%;
      padding: 10px;
    }
  }

  .report-time-section {
    color: var(--color-gray-dark);
    font-style: italic;
    flex: 0 0 40%;
    padding: 0 20px;

    @media screen and (max-width: 63.9375em) {
      font-size: 13px;
      padding: 10px;
      width: auto;
      flex: unset;
      margin-left: auto;
    }
  }

  @media screen and (max-width: 63.9375em) {
    flex-wrap: wrap;
    padding: 10px 0 0;
  }

  .name-section {
    padding-left: 10px;
    font-weight: 600;
    display: inline-block;
    width: 100%;
    flex-direction: column;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media screen and (max-width: 63.9375em) {
      border-bottom: 1px solid var(--color-gray-light);
      padding-bottom: 10px;
    }
  }

  .action-section {
    margin-left: auto;

    @media screen and (max-width: 63.9375em) {
      width: 100%;

      .select-btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        width: 100%;
      }
    }

    .select-btn {
      min-width: 95px;
    }
  }
}
