.add-question-btn {
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: var(--color-gray-nice);
  width: 100%;
  position: relative;
  background-color: white;
  z-index: 2;

  .btn-label {
    cursor: pointer;
    z-index: 1;
    padding: 0 5px;
    background-color: white;
  }

  &:hover {
    color: var(--color-primary);

    &::after {
      border-color: var(--color-primary);
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    border-bottom: 1px dashed var(--color-gray-nice);
  }
}

.category {
  &:not(:first-child) {
    padding-bottom: 30px;
  }

  .questions {
    position: relative;
  }
}

.empty-container {
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  background-color: var(--color-white-bg);
  border: 2px dashed #ddd;
  font-size: 13px;
  border-radius: 8px;
  color: var(--color-gray-dark);
}

.category-header {
  display: flex;
  align-items: center;

  .btn-group {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: 14px;
    color: var(--color-gray-nice);
  }

  .btn {
    cursor: pointer;
    margin-left: 10px;

    &:hover {
      text-decoration: underline;
    }
  }
}
