@import '../../../../styles/variables';

.header {
  margin: 0;
  padding: 0px 10px;
  color: $nice-grey;
  text-align: center;
  background: $background;
  border-bottom: 1px solid $light-gray;
  font-size: 10px;
}

.error {
  color: $brand-red;
  background-color: #ffe8df;
}

.info {
  color: $brand-blue;
  background-color: $light-blue;
  text-transform: uppercase;
}
