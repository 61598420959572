.warning-text {
    font-size: 24px;
    padding: 0 30px;

    &>div {

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .brand-orange {
        color: var(--color-orange-brand);
    }

    .brand-blue {
        color: var(--color-blue-brand);
    }
}

.timestamp {
    padding: 10px 0 20px;
    font-size: 12px;
    color: var(--color-gray-dark)
}

.warning-collapse-box {
    margin: 15px 0;

    .warning-group-info {
        padding: 7px 10px;
    }
}