@import "styles/variables";

.farm-row {
  font-size: 14px;
  padding: 10px;
  border: 1px solid var(--color-gray-light);
  min-height: 60px;
  background-color: $background;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 63.9375em) {
    flex-wrap: wrap;
    padding: 10px 0 0;
  }

  .name-section {
    display: flex;
    flex: 0 0 30%;
    flex-direction: column;

    @media screen and (max-width: 63.9375em) {
      flex: 1;
    }

    .farm-manager {
      color: var(--color-gray-dark);
      font-size: 12px;
    }

    .farm-name {
      font-weight: 600;
    }
  }

  .report-time-section {
    color: var(--color-gray-dark);
    font-style: italic;
    width: 100%;
    padding: 0 20px;

    @media screen and (max-width: 63.9375em) {
      margin-top: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-top: 1px solid var(--color-gray-light);
    }
  }

  .action-section {
    @media screen and (max-width: 63.9375em) {
      width: 100%;

      .select-btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        width: 100%;
      }
    }

    .select-btn {
      min-width: 95px;
    }
  }
}

.vertical-divider {
  flex: 0 0 1px;
  border-right: 1px solid var(--color-gray-light);
  margin-left: 15px;
  margin-right: 15px;
  height: 40px;

  @media screen and (max-width: 63.9375em) {
    display: none;
  }
}

.icon-section {
  flex: 0 0 35px;
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

.red-marker {
  display: block;
  background-color: var(--color-primary);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-icon {
  font-size: 25px;
  color: var(--color-gray-medium);
}
