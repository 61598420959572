@import "styles/variables";

.question-row {
  padding: 15px 20px;
  border-bottom: 1px solid #ddd;
  position: relative;

  @media (max-width: 40em) {
    &:last-child {
      border-bottom: none;
    }
  }

  &.flagged {
    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 15px 15px 0 0;
      border-color: $brand-orange transparent transparent transparent;
    }
  }

  .header {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 40em) {
      flex-direction: column;
    }

    .info-column {
      display: flex;
      align-items: center;

      @media (max-width: 40em) {
        flex-direction: column;
        margin-bottom: 40px;
      }
    }

    .btn-group {
      display: flex;

      @media (max-width: 40em) {
        width: 100%;
      }

      .btn {
        margin-left: 15px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 14px;
        height: 45px;
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        cursor: pointer;
        transition: 0.3s;

        @media (max-width: 40em) {
          margin-left: 0;
          margin-right: 10px;
          flex: 1;
        }

        &.selected {
          pointer-events: none;
        }

        &.pass {
          color: $brand-blue;
          border: 1px solid $brand-blue;

          &.selected,
          &:hover {
            background-color: $brand-blue;
            color: white;
          }
        }

        &.fail {
          color: $brand-red;
          border: 1px solid $brand-red;

          &.selected,
          &:hover {
            background-color: $brand-red;
            color: white;
          }
        }

        &.not_available {
          background-image: linear-gradient(
            0deg,
            rgba(245, 245, 245, 0.68) 2%,
            rgba(255, 255, 255, 0.68) 100%
          );
          color: $dark-gray;
          border: 1px solid #ddd;

          &.selected,
          &:hover {
            background-color: $brand-gray;
            color: $black;
          }
        }
      }

      .note-btn {
        cursor: pointer;
        transition: 0.3s;
        margin-left: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        width: 45px;
        background-image: linear-gradient(
          0deg,
          rgba(245, 245, 245, 0.68) 2%,
          rgba(255, 255, 255, 0.68) 100%
        );
        border: 1px solid #ddd;
        border-radius: 50%;

        &.disabled {
          cursor: not-allowed;
        }

        @media (max-width: 40em) {
          margin-left: auto;
        }

        &:hover {
          background-color: $brand-gray;
        }

        &.opened {
          background-image: none;
          background-color: $black;
          border: none;
          color: white;
        }
      }
    }
  }
}

.note-section {
  padding-top: 15px;

  .note-actions {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 40em) {
      justify-content: space-between;
    }
  }

  .save-btn {
    margin-left: auto;
    height: 35px;
    padding: 0 20px;
  }

  .note-area {
    padding: 10px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 90px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    color: $black;

    @media (max-width: 40em) {
      min-height: 140px;
    }
  }

  textarea {
    resize: none;
    display: block;
    box-sizing: border-box;
    width: 100%;
    font-family: inherit;
    font-size: 14px !important;
    color: var(--color-black);
    transition: box-shadow .5s,border-color .25s ease-in-out;
    -webkit-appearance: none;
    margin: 0 !important;
    padding: 5px 10px;
    border: 1px solid var(--color-gray-light);
    box-shadow: none;
    overflow: hidden;
    word-break: break-word!important;
    min-height: 90px !important;
    max-height: 150px !important;

    &::placeholder {
      color: var(--color-gray-medium);
    }
  }
}
