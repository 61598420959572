.treatment-protocols {
  display: flex;
  flex-direction: column;

  .filters {
    padding: 0;
    z-index: 1;
    position: relative;
    margin-top: 10px;
  }

  .tp-farms-panel {
    position: relative;
    z-index: 0;
    margin: 15px 0 20px 0;
  }

  &.is-desktop {
    .tp-farms-panel {
      margin-top: 5px;
    }
  }
}
