@import '../../../../styles/variables';

.FarmFeedFarmsSelect {

  .farms-line {
    min-height: 25px;
    margin-left: 15px;
  }

  .farms-search {
    display: flex;
    padding: 15px;

    .select-badge {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      font-weight: 600;
      cursor: pointer;
      flex: 0 0;
      padding: 0 10px;
      display: flex;
      background-color: $light-gray;
      align-items: center;
      justify-content: center;
    }

    .farm-select {
      flex: 1;

      .Select-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        .Select-value {
          border: none;
          border-radius: 3px;
          color: $primary;
          background-color: #FFE8DF;

          .Select-value-label {
            padding: 4px 8px;

            .remove-icon {
              font-size: 10px;
              margin-left: 5px;
              cursor: pointer;
            }
          }
        }
      }

      .farm-suggestion-item {
        display: flex;
        align-items: center;
      }

      .farm-avatar,
      .Avatar {
        margin-right: 10px;
      }

      .icon {
        display: inline-block;
        width: 25px;
        text-align: center;
        font-size: 22px;
        color: $black;
        margin-right: 10px;
      }

      .muted {
        color: $dark-gray;
        font-size: 12px;
      }
    }
  }
}
