.radio-item {
    display: flex;
    align-items: center;
  
    @media (max-width: 63em) {
      flex-direction: column;
      align-items: start;
      margin-bottom: 20px;
  
      .radio-label {
        margin-top: 20px;
      }
    }
  
    .unit-select {
      min-width: 120px;
    }
  
    .days-input {
      max-width: 45px;
    }
  
    .radio-label {
      display: flex;
      align-items: center;
    }
  }
  
.info-row {
  display: flex;
  align-items: center;
  background-color: var(--color-white-almost);
  padding: 15px 10px;

  .icon {
    margin-right: 10px;
    font-size: 18px;
  }
}

.label-text {
  width: 40px;
  text-align: center;
}

.frequency-field {
  display: flex;
  align-items: center;
}

.frequency-field > * {
  margin-right: 10px;
}

.days-input {
  max-width: 50px;
}
