@import "../../../../styles/variables";

.group-field {
  padding: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-light);
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .edit-source-row {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.abf-field {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .radio-button-group {
    min-width: 220px;
  }
}

.dof-info {
  margin: 0;
  color: $nice-grey;
  font-size: 14px;
  font-weight: 400;
}
