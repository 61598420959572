.FarmBins {
  .double-line-img {
    max-width: 30px;
    margin-right: 10px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &.no-drop {
      cursor: no-drop;
    }
  }

  .last-row {
    border-radius: 0 0 5px 5px;
    border-bottom: none;
  }
}
