@import "styles/variables";

.general-section {
  margin-top: 40px;
  width: 100%;

  .action-buttons {
    display: flex;
    justify-content: space-between;
  }

  .notes-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .save-btn {
      margin-top: 15px;
      margin-left: auto;
    }
  }

  @media (max-width: 64em) {
    padding: 0 20px;
    margin-top: 20px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .textarea {
    padding: 10px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    min-height: 90px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    color: $black;

    @media (max-width: 40em) {
      min-height: 130px;
    }
  }
}
