.icon-composition {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .ep-app-icon {
    background-color: var(--color-primary);
    font-size: 50px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    border-radius: 10px;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 80px;
      height: 80px;
      background-image: linear-gradient(to left, var(--color-white-almost), transparent);
      position: absolute;
      left: -120%;
      border-radius: 10px;
    }

    &:after {
      content: '';
      display: block;
      width: 80px;
      height: 80px;
      background-image: linear-gradient(to right, var(--color-white-almost), transparent);
      position: absolute;
      right: -120%;
      border-radius: 10px;
    }
  }
}

.instruction {
  margin-top: 10px;
  font-size: 14px;
  align-items: center;
  display: flex;

  .download-icon {
    fill: #4286F4;
    margin: 0 8px;
  }
}
