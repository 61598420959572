@import "styles/variables";

.media-asset {
  display: flex;
  flex-wrap: wrap;

  .asset-wrapper {
    width: 120px;
    height: 120px;
    margin-bottom: 10px;
    margin-right: 10px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(black, 0.3);
      }
    }

    .preview-image {
      background-color: rgba(0, 0, 0, 0.2);
      width: 120px;
      height: 120px;
      position: relative;
      background-size: cover;
    }
  }
}