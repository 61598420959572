.svr-comment {
  font-weight: normal;
  display: flex;
  padding-bottom: 15px;

  &:first-child {
    padding-top: 15px;
  }

  .comment-body {
    width: 100%;
    margin-left: 15px;

    .comment {
      font-weight: normal;
      padding: 10px 15px;
      border-radius: 10px;
      max-height: 150px;
      overflow-y: scroll;
      font-size: 14px;
      background-color: var(--color-white-almost);
      animation: slide-in-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      .author {
        font-weight: 600;
        margin-bottom: 10px;
      }
    }

    .edit-section {
      animation: slide-in-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      .btn-group {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;

        .btn {
          display: flex;
          align-items: center;
          margin-left: 10px;
        }
      }
    }

    .bottom-line {
      margin-top: 5px;
      font-size: 12px;
      display: flex;
      align-items: center;

      .action-btn {
        cursor: pointer;
        margin-left: 10px;
        color: var(--color-primary);
      }

      .time {
        color: var(--color-gray-dark);
      }
    }
  }
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
