@import '../../../../styles/variables';

.FarmHealthVariables {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .farm-health-wrapper {
    padding-right: 15px;
  }

  .panel-body {
    background-color: #fff;
    padding: 20px;
    position: relative;
  }

  @media screen and (max-width: 74.9375em) {

    .farm-health-wrapper {
      padding-right: 0;
      padding-bottom: 15px;
    }
  }

  .diagnosis-line {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px dashed $alto;
    flex-wrap: wrap;
    font-size: 14px;
  }

  .toggle-wrapper {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: $dark-gray;

    .switcher {
      margin-left: 15px;
    }

    &.isActive {
      color: $primary;
    }
  }

  .bold {
    font-weight: 600;
  }

  .comment-box {
    width: 100%;
  }
}

.add-vaccine-btn {
  font-size: 14px;
  color: $secondary;
  user-select: none;
  padding: 10px;
  cursor: pointer;
}
