@import 'styles/variables';

.DailyCheckupOrderFeed {
  .block {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $light-gray;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }

    .input-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: start;
      margin-bottom: 10px;

      .index-cell {
        flex: 0 0 50px;
        display: flex;
        align-items: center;
        align-self: start;

        @media(max-width: 40em) {
          width: 25px;
          height: 25px;
        }
      }

      .select-wrapper {
        width: 100%;
        align-items: center;
      }

      .trash-cell {
        flex: 0 0 45px;
      }

      @media all and (max-width: 63.9375em) {

        .input-wrapper {
          flex-wrap: wrap;

          .input-label {
            flex: 0 0 100%;
            text-align: left;
            margin-bottom: 5px;
          }

          .migration-input {
            flex: 1;
          }
        }
      }
    }

    .row-index {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #333;
      border-radius: 50%;
      font-size: 14px;
      color: #333;
    }

    .trash-icon {
      padding: 9px;
      margin-left: 5px;
      border-radius: 5px;
      transition: background-color .2s ease;
      cursor: pointer;
      font-size: 22px;
      color: $light-black;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .input-wrapper {
      display: flex;
      font-size: 14px;
      border: none;

      .input-label {
        flex: 0 0 25%;
        width: 100%;
        font-weight: 600;
        text-align: right;
        line-height: 18px;
        padding-right: 10px;
      }

      .migration-input {
        width: 130px;
        margin-bottom: 0;

        &.wide {
          flex: 1;
        }
      }
    }

    .migration-inputs {

      .location-message-box {
        margin-left: 160px;
        width: auto;
      }

      .add-location {
        margin-left: 160px;
        cursor: pointer;
        color: $brand-blue;
        line-height: 30px;
        font-size: 14px;
      }

      @media all and (max-width: 63.9375em) {

        .location-message-box,
        .add-location {
          margin-left: 0;
        }
      }

      .location-row {
        display: flex;
        width: 100%;
      }
    }
  }

  .add-bin-button {
    display: block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    color: $brand-blue;
    background-color: $light-blue;
    line-height: 40px;
  }

  .input-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .input-label {
      display: inline-block;
      font-size: 14px;
      font-weight: 600;
    }

    .range-container {
      width: 100%;
    }

    .date-input {
      max-width: 240px;

      @media(max-width: 40em) {
        max-width: unset;
      }
    }

    .range-input {
      align-items: start;
    }
  }

  .bin-quantity-label {
    font-size: 14px;
  }

  .total-quantity-line {
    border-bottom: 1px solid $light-gray;
    background-color: $almost-white;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &.mobile {
      background-color: #131830;
      color: $white;
      border-bottom: none;
      max-height: 38px;

      .icon {
        display: none;
      }
    }

    @media(max-width: 40em) {
      padding: 10px;
    }

    .left-value {
      display: flex;
      justify-content: center;

      .icon {
        font-size: 25px;
        margin-right: 10px;
      }
    }
  }

  .input-label-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .total-quantity-line {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .quantity-container {
      display: flex;
      align-items: baseline;
      gap: 5px;

      .quantity-tons {
        min-width: 65px;
      }
    }
  }

  .bin-quantity-label {
    display: flex;
    align-items: baseline;
    white-space: nowrap;
    gap: 5px;

    .bin-quantity-tons {
      min-width: 55px;
    }
  }
}
