@import "../../styles/variables";

.speech-to-text {
  display: flex;
  align-items: center;

  .control-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    padding: 0;
    margin: 0;
    color: $almost-black;
    background-color: #F5F5F5;
    transition: all 0.2s ease;

    &.primary {
      background-color: transparent;
      background-image: linear-gradient(0deg, rgb(255, 94, 58) 3%, rgb(247, 132, 69) 103%);
      color: $white;
      font-size: 24px;

      &:hover {
        background-image: linear-gradient(0deg, rgb(255, 94, 58) 3%, rgb(247, 132, 69) 103%);
        opacity: 0.9;
      }
    }

    &.secondary {
      background-color: transparent;
      background-image: linear-gradient(0deg, rgb(0, 105, 161) 23%, rgb(62, 130, 201) 103%);
      color: $white;
      font-size: 24px;

      &:hover {
        background-image: linear-gradient(0deg, rgb(0, 105, 161) 23%, rgb(62, 130, 201) 103%);
        opacity: 0.9;
      }
    }

    .speech-icon {
      margin-right: 0;
      font-size: 24px;
    }

    .speech-icon-small {
      margin-right: 0;
      font-size: 18px;
    }
  }

  .cb-small {
    width: 30px;
    height: 30px;
  }

  .cb-medium {
    width: 35px;
    height: 35px;
  }
}
