@import "styles/variables";

.treatment-protocols-farm-row {
  font-size: 14px;
  padding: 10px;
  border: 1px solid var(--color-gray-light);
  min-height: 60px;
  background-color: var(--color-white-bg);
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 63.9375em) {
    flex-wrap: wrap;
    padding: 10px 0 0;
    align-items: baseline;
  }

  .name-section {
    display: flex;
    align-items: center;
    flex: 0 0 40%;

    @media screen and (max-width: 63.9375em) {
      flex: 1;
      margin-bottom: 10px;
    }

    .farm-name-wrapper {
      font-weight: 600;
      align-items: center;
      display: flex;

      @media screen and (max-width: 63.9375em) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .farm-name {
      font-weight: 600;
      align-items: center;
      display: flex;
    }

    .group-name {
      min-width: 120px;

      @media screen and (max-width: 63.9375em) {
        min-width: unset;
      }
    }

    .farm-info {
      display: flex;
      align-items: center;
    }
  }

  .action-section {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 63.9375em) {
      width: 100%;

      .select-btn {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        width: 100%;
      }
    }

    .select-btn {
      min-width: 95px;
    }
  }

  .pigs-count-section {
    display: flex;
    width: 130px;

    @media screen and (max-width: 63.9375em) {
      width: 100%;
      padding: 10px;
      border-top: 1px solid var(--color-gray-light);
    }
  }
}

.icon-section {
  flex: 0 0 35px;
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

.red-marker {
  display: block;
  background-color: var(--color-primary);
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-icon {
  font-size: 25px;
  color: var(--color-gray-medium);
}

.stats-section {
  display: flex;
  color: var(--color-gray-dark);
  font-style: italic;

  @media screen and (max-width: 63.9375em) {
    border-top: 1px solid var(--color-gray-light);
    width: 100%;
    padding: 10px 0;
  }

  .stats-item {
    padding: 0 10px;
    display: flex;
    align-items: center;
  }

  .count-badge {
    font-style: normal;
    background-color: var(--color-primary);
    color: white;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-size: 11px;

    &.inactive {
      background-color: var(--color-gray-dark);
    }
  }
}

.vertical-divider {
  border-right: 1px solid var(--color-gray-light);
  margin-left: 15px;
  margin-right: 15px;
  height: 40px;

  @media screen and (max-width: 63.9375em) {
    display: none;
  }
}
