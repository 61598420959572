@import "styles/variables";

// MediaFilesUploader

.media-files-uploader {
  position: relative;

  &__header {
    height: 60px;
    font-weight: 600;
    padding: 0 20px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    font-size: 16px;
    border-bottom: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    @media (max-width: 40em) {
      height: 50px;
      border-bottom: none;
    }
  }

  &__body {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: $background;
    min-height: 200px;
    border: 2px dashed #ddd;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &.dragOver {
      border-color: $primary;
    }
  }

  .browse-mobile-btn {
    display: block;
    height: 45px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    @media (min-width: 40em) {
      display: none;
    }
  }
}

// UploadPlaceholder

.upload-placeholder {
  min-height: 200px;
  padding: 30px 0;
  font-weight: 600;
  color: $dark-gray;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .placeholder-icon {
    font-size: 50px;
    color: #d1d1d1;
    transition: color 0.3s ease;
  }

  &__browse-btn {
    pointer-events: none;

    @media (min-width: 40em) {
      pointer-events: auto;
      cursor: pointer;
      color: $primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

// UploadingFilePreview & UploadedFilePreview

.file-preview {
  border-top: 1px solid #ddd;
  width: 100%;
  display: flex;
  padding: 10px;
  height: 80px;
  align-items: center;

  .file-data {
    overflow: hidden;
    justify-content: center;
    width: 100%;
    margin-bottom: auto;
    padding-left: 10px;
    display: flex;
    flex-direction: column;

    .file-info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .file-name {
      font-weight: 600;
      min-width: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 2px;
    }

    .file-size {
      font-size: 12px;
    }

    .file-type {
      font-size: 11px;
      font-weight: normal;
      color: $dark-gray;
    }

    .file-upload-progress {
      background-color: $medium-gray;
      height: 5px;
      width: 100%;
      margin: 5px auto;
      position: relative;

      .file-progress-value {
        background-color: $primary;
        height: 5px;
        width: 0;
        transition: width .4s ease;
      }
    }

    .file-uploading-label {
      font-size: 12px;
    }
  }

  .remove-file-btn {
    padding: 10px 20px;
    color: $dark-gray;
    font-size: 22px;
    margin-left: auto;
    cursor: pointer;

    &:hover {
      color: $black;
    }
  }

  .cancel-file-upload {
    padding: 10px 20px;
    cursor: pointer;
  }

  .preview-image {
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
  }

  .preview-image-wrapper {
    cursor: pointer;
    min-width: 100px;
    height: 100%;
    position: relative;

    &:hover {

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(black, 0.3);
      }
    }
  }
}