@import '../../../styles/variables';

.title {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.fa-info-circle {
  font-size: 20px;
  color: $dark-gray;
  font-weight: 600;
  cursor: pointer;
}

.dropdown-info-item {
  &:hover {
    background-color: inherit;
  }

  a {
    white-space: pre-line;
    cursor: default;
    padding: 5px 15px;
    font-size: 14px;
  }
}
