@import '../../../styles/variables';

.DailyCheckupReportTreatments {
  .useTreatmentInput {
    display: flex;
    justify-content: space-between;
    padding: 6px 0;

    .treatmentInputLabel {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .brg-field {
      align-items: center;

      .radio-button-group {
        min-width: 230px;
        margin-left: 10px;
      }
    }

    .label-wrapper {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 5px;
    }
  }

  .total-count {
    border-top: 1px solid var(--color-gray-light);
    padding-top: 20px;
  }

  .treatment-row {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-gray-light);
    font-size: 15px;

    &.disabled {
      .mobile-treatment-input,
      .index-cell {
        opacity: 0.4;
        pointer-events: none;
      }
    }

    .oral-attention {
      display: block;
      margin-bottom: 10px;
      font-weight: 400;
    }

    &:last-child {
      border-bottom: none;
    }

    .select-treatment-line,
    .select-values-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      &.skip-margin-bottom {
        margin-bottom: 0;
      }

      .index-cell {
        flex: 0 0 50px;
        display: flex;
        align-items: center;
        align-self: center;
      }

      .select-treatment-wrapper,
      .treatment-details,
      .treatment-efficacy-survey {
        flex: 1 1 160px;
      }

      .trash-cell {
        flex: 0 0 45px;
      }
    }

    .row-index {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #333;
      border-radius: 50%;
      font-size: 14px;
      color: #333;
    }

    .trash-icon {
      padding: 9px;
      margin-left: 5px;
      border-radius: 5px;
      transition: background-color .2s ease;
      cursor: pointer;
      font-size: 22px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }

    .select-treatment-wrapper {
      .Select-control {
        margin-bottom: 0;
      }

      .mobile-treatment-input {
        position: relative;

        .placeholder {
          color: var(--color-gray-dark);
        }

        .treatment-name {
          border-radius: 2px;
          padding: 7px 30px 7px 7px;
          border: 2px solid var(--color-gray-light);
          color: var(--color-black);
          font-size: 14px;
        }

        .change-treatment-icon {
          position: absolute;
          right: 0;
          top: 0;
          padding: 11px;
        }
      }

      .Select-menu {
        max-height: 298px;
        overflow-x: hidden;
      }

      .Select-menu-outer {
        max-height: 300px;

        .Select-option {

          &:last-child {
            border-bottom: none;
          }
        }

        .medication {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          position: relative;

          &.divider{
            margin-top: 10px;

            &::before {
              content: ' ';
              display: block;
              height: 10px;
              width: calc(100% + 30px);
              background-color: var(--color-white-bg);
              border-bottom: 1px solid var(--color-gray-light);
              position: absolute;
              top: -19px;
              left: -15px;
            }
          }

          .right-text {
            margin-left: auto;
            color: var(--color-gray-dark);
          }

          i {
            font-size: 22px;
            margin-right: 10px;
          }
        }
      }
    }

    .treatment-efficacy-survey {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border: 0;
      padding: 6px 0;

      @media screen and (min-width: 64em) {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }

      .tes-label {
        font-weight: 600;
        font-size: 14px;
      }

      .tes-options {
        width: 100%;

        @media screen and (min-width: 64em) {
          margin-left: 10px;
          width: 234px;
        }

        label input[value=false]:checked + span {
          background: var(--color-gray-dark);
          border-color: var(--color-gray-dark);
        }
      }
    }
  }

  .treatment-details {
    label {
      border: none;
    }

    .treatment-details-row {
      display: flex;
      justify-content: flex-end;

      .icon {
        flex: 0 0 50px;
        visibility: hidden;

        &.visible {
          padding: 6px 10px 6px 0;
          align-self: flex-end;
          text-align: center;
          line-height: 40px;
          color: var(--color-orange);
          visibility: visible;
        }
      }

      @media all and (max-width: 63.9375em) {

        .treatment-number-input {
          width: 100%;
        }
      }
    }
  }

  .add-treatment {
    display: block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    color: var(--color-blue-brand);
    background-color: var(--color-blue-light);
    line-height: 40px;
  }

  @media screen and (max-width: 63.9375em) {

    .select-values-line {
      .index-cell,
      .trash-cell {
        display: none !important;
      }
    }

    .useTreatmentInput {
      width: 100%;

      .treatmentInputLabel {
        flex-direction: column;
        align-items: flex-start;
        border: 0;
        flex: 1 1 160px;

        .step-buttons {
          margin-left: 0;
          width: 100%;
        }

        .brg-field {
          width: 100%;

          .radio-button-group {
            margin-left: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
