@import "styles/variables";

.status {
  margin-right: 30px;
  text-transform: uppercase;
  font-weight: 800;
  border: 1px solid;
  font-size: 13px;
  padding: 2px 5px;
  border-radius: 3px;

  &.small {
    font-size: 10px;
    margin-right: 10px;
  }

  &.pass {
    border-color: $brand-blue;
    color: $brand-blue;
  }

  &.fail {
    border-color: $brand-red;
    color: $brand-red;
  }

  &.not_available {
    border-color: $dark-gray;
    color: $dark-gray;
  }

  &.fill_blank {
    border-color: $brand-orange;
    color: $brand-orange;
  }

  &.empty {
    border-color: #ddd;
    color: #ddd;
  }
}

// MOBILE

.status {
  @media (max-width: 40em) {
    margin-right: 10px;
  }
}
