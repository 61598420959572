.protect-container {
  position: relative;

  .protector {
    position: absolute;
    z-index: 4;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, .5);
    cursor: not-allowed;
  }
}
