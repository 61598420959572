@import '../../../../styles/variables';

.activity-assets {
  position: relative;
  height: 370px;
  margin-bottom: 10px;
  border-radius: 3px;
  background: $white;

  .farmfeed-assets {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    height: 100%;
    border-radius: 6px;
  }

  .smart-suggestions {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 45px;
    opacity: 0.95;
    border-radius: 25px;
    background-color: #373636;
    font-size: 14px;
    color: $white;
    font-weight: 600;
  }

  .magic-loop-img {
    max-width: 35px;
    margin: 0 10px;
    position: absolute;
  }

  .suggestions-text {
    padding: 0 10px 0 55px;
    white-space: nowrap;
  }

  .column-left {
    flex: 5 1 auto;
    display: flex;
    flex-direction: column;
  }

  .column-right {
    flex: 4 1 auto;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
  }

  .asset-preview {
    flex: 1 1 100%;
    position: relative;
    overflow: hidden;

    &:hover {
      .image {
        transform: scale(1.08);
      }
    }
  }

  .image {
    background-color: #eee;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transition: transform .4s ease;
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  .more-assets-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    font-size: 32px;
    font-weight: 600;
    pointer-events: none;
  }
}