.speech-to-text-container {
  display: flex;
  align-items: center;
  gap: 4px;

  .speech-to-text {
    margin-top: 0;
    height: 30px;
  }
}

.mentions-input-block {
  flex: 1;
}
