@import 'styles/variables';

.warning-text {
  font-size: 24px;
  padding: 0 30px 10px;

  & > div {

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .brand-orange {
    color: $brand-orange;
  }

  .brand-blue {
    color: $brand-blue;
  }
}

.timestamp {
  padding: 10px 0;
  font-size: 12px;
  color: $dark-gray;
}

.collapse {
  overflow: hidden;
  padding: 0;
  border-radius: 5px;
  border: 1px solid var(--color-gray-light);
  font-size: 14px;

  &.child {
    border: none;
    border-radius: 0;

    .collapse-title {
      font-weight: normal;
    }
  }
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-light);
  }

  .farm-name {
    font-size: 13px;
    flex-basis: 35%;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    margin-right: 5px;
  }

  .group-name {
    font-size: 13px;
    flex-basis: 30%;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    margin: 0 5px;
  }

  .integration-name {
    font-size: 13px;
    flex-basis: 20%;
    word-wrap: break-word;
    margin: 0 5px;
  }

  .value {
    font-weight: 600;
    font-size: 13px;
    flex-basis: 15%;
    word-wrap: break-word;
    margin-left: 5px;
  }

  .integration-badge {
    min-width: 50px;
    padding: 2px 6px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
    background-color: transparent;
    color: $brand-blue;
    border: 1px solid $brand-blue;
  }
}

.collapse-body {
  max-height: 250px;
  overflow: scroll;
  padding: 0 15px;
}
