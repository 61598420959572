.basic-plan-content-wrapper {
  position: relative;

  .content-blocker {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(white, .5);
    cursor: not-allowed;
    z-index: 1;
  }

  .content-blocker-label {
    position: relative;
    z-index: 2;
  }

  .content-blocker-message-box {
    max-width: 820px;
    margin: 15px 0 10px 0;
  }
}
