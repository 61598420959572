@import "../../../../styles/variables";

.Preferences {
  $left-indent: 50px;
  $left-indent-small: 0;

  .setting-section {
    position: relative;
    margin-top: 0;
    padding-bottom: 20px;
    padding-left: $left-indent;

    &:not(:last-of-type)::after {
      display: block;
      position: absolute;
      content: "";
      bottom: 0;
      left: $left-indent;
      right: -10px;
      border-bottom: 1px solid $light-gray;
    }

    .generate-alert {
      display: flex;
      width: 100%;
      padding-top: 26px;
      padding-left: 20px;
      padding-bottom: 26px;
      padding-right: 15px;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #dddddd;
      border-radius: 5px;

      .description {
        font-size: 14px;
        color: #303030;
      }

      .content-container {
        display: flex;
        align-items: center;

        .percent-box {
          display: flex;
          align-items: center;
          margin-left: 20px;

          input {
            max-width: 48px;
            margin: 0;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }

          span {
            width: 40px;
            border: 2px solid #e6e6e6;
            border-radius: 5px;
            background-color: #fafafa;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -1px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
          }
        }
      }
    }

    .save-weight-btn {
      margin-right: 15px;
    }

    @media screen and (max-width: 40em) {
      padding-left: $left-indent-small;

      &:not(:last-of-type)::after {
        left: $left-indent-small;
      }

      .button {
        font-size: 13px;
      }

      .save-weight-btn {
        margin-top: 5px;
        width: 100%;
      }
    }
  }

  .reconciliation-select,
  .report-select {
    margin-right: 15px;

    .Select {
      display: inline-block;
      min-width: 215px;

      .Select-control {
        margin: 0;

        .Select-arrow-zone {
          background-color: $background;
          border-left: 2px solid $light-gray;
        }
      }

      .Select-menu-outer {
        min-width: auto;
      }
    }
  }

  .withdrawal {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    & input {
      margin-right: 15px;
      max-width: 225px;
    }
  }

  .body-title {
    font-size: 16px;
    margin: 10px 0;
  }

  .info-box {
    margin-top: 10px;
    font-size: 14px;
    margin-right: 15px;

    .info-text {
      font-weight: 400;
    }
  }

  .meta-farms-integration {
    .message-box {
      margin-bottom: 10px;

      .MessageBox__icon {
        font-size: 20px;
      }

      .MessageBox__message {
        padding-left: 0;
      }
    }
  }
  .distynct-integration {
    .message-box {
      margin-bottom: 10px;

      .MessageBox__icon {
        font-size: 20px;
      }

      .MessageBox__message {
        padding-left: 0;
      }
    }
  }
}
