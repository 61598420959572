@import '../../../../styles/variables';

.BarnSheetsWaterUsageTable {
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .fa-dots-three-horizontal {
      color: $brand-gray;
      font-size: 25px;
      cursor: pointer;
    }
  }

  .integration-badge {
    min-width: 50px;
    padding: 2px 6px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    font-weight: 600;
    background-color: transparent;
    color: $brand-blue;
    border: 1px solid $brand-blue;
  }

  .percent-change {
    color: $brand-blue;
    font-weight: 600;
  }

  .red {
    color: $brand-red;
  }

  .pink {
    background-color: $light-orange;
  }
}
