.product-select {
  position: relative;

  .medication {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;

    &.divider {
      margin-top: 10px;

      &::before {
        content: ' ';
        display: block;
        height: 10px;
        width: calc(100% + 30px);
        background-color: var(--color-white-bg);
        border-bottom: 1px solid var(--color-gray-light);
        position: absolute;
        top: -19px;
        left: -15px;
      }
    }

    .right-text {
      margin-left: auto;
      color: var(--color-gray-dark);
    }

    i {
      font-size: 22px;
      margin-right: 10px;
    }
  }

  .trash-btn {
    margin-bottom: 15px;
    position: absolute;
    left: 100%;
    bottom: 0;
  }
}

.product-section {
  max-width: 860px;
  padding: 0 15px;

  @media screen and (max-width: 63em) {
    max-width: unset;
  }
}

.product-comment {
  padding: 0 15px;
  max-width: 760px;

  @media screen and (max-width: 63em) {
    max-width: unset;
  }
}

.withdrawal-suggestion {
  margin-bottom: 15px;
  font-style: italic;
  color: var(--color-gray-dark);
}

.section-collapse {
  padding: 0;
  border-radius: 3px;
  border: 1px solid var(--color-gray-light);

  .collapse-content {
    padding: 20px;
    border-top: 1px solid var(--color-gray-light);
  }
}
