@import 'styles/variables';

.NameWithAddressForm {

  .company-marker {
    color: $primary;
    font-size: 30px;
    transform: translate(-50%, -100%);
  }

  .content-block {
    display: flex;
    padding-top: 10px;
  }

  .form-content-block {
    flex: 0 0 30%;
  }

  .map-block {
    flex: 1 1 100%;
    margin: 15px 15px 15px 0;
    z-index: 1;
    max-height: 700px;
    min-height: 500px;
  }

  .checkbox-container {
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    margin-left: 15px;
    margin-right: 15px;
  }

  .checkbox-row {
    font-size: 14px;
    padding: 15px 0;
    background-color: $background;
    border-bottom: 1px solid $light-gray;

    &.selected {
      background-color: initial;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .button-submit {
    margin: 30px 15px 15px;
  }

  .message-box {
    width: auto;
    margin: 15px;
  }

  @media screen and (max-width: 96em) {
    .form-content-block {
      flex: 0 0 50%;
    }
  }

  @media screen and (max-width: 64em) {
    margin-left: 10px;
    margin-right: 10px;

    .content-block {
      display: block;
    }

    .map-block {
      height: 400px;
      margin: 10px;
    }

    .button-submit, .message-box {
      margin: 10px;
    }
  }
}
