@import '../../../styles/variables';

.abf-status-badge {
  color: $white;
  min-width: 50px;
  padding: 2px 6px;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
  font-weight: 600;
  background-color: $brand-blue;
}
