@import "../../../../styles/variables";

.MobileRow {
  border-top: 1px solid $light-gray;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  position: relative;

  &.select-row {
    padding: 7px 10px;
  }

  &.no-events {
    pointer-events: none;

    .icon {
      opacity: .2;
    }
  }

  .selected-disease {
    color: $brand-gray;
  }

  .select-btn {
    margin-bottom: 0;
    padding: 10px 7px;
  }

  .icon {
    position: relative;
    width: 25px;
    height: 25px;
    color: $primary;
  }
}
