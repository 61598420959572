@import 'styles/variables';

.warning-text {
  font-size: 24px;
  padding: 0 30px 10px;

  & > div {

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .brand-orange {
    color: $brand-orange;
  }

  .brand-blue {
    color: $brand-blue;
  }
}

.timestamp {
  padding: 10px 0;
  font-size: 12px;
  color: $dark-gray;
}

.collapse {
  overflow: hidden;
  padding: 0;
  border-radius: 5px;
  border: 1px solid var(--color-gray-light);
  font-size: 14px;

  &.child {
    border: none;
    border-radius: 0;

    .collapse-title {
      font-weight: normal;
    }
  }
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  align-items: center;
  padding: 5px 0;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-light);
  }

  .group-name {
    font-size: 13px;
  }

  .value {
    font-weight: 600;
    font-size: 16px;
    margin-left: auto;
  }
}

.collapse-body {
  max-height: 250px;
  overflow: scroll;
  padding: 0 15px;
}
