@import '../../../../../styles/variables';

.group-create-field {
  position: relative;
  transition: background-color .5s;

  &.blackout {
    background-color: $background;
  }

  .disabled-overlay {
    background-color: $background;
    transition: visibility .5s, opacity .5s;
    cursor: not-allowed;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    &.visible {
      visibility: visible;
      opacity: .6;
    }
  }
}

.field-row {
  display: flex;
  align-items: flex-start;

  .icon {
    font-size: 25px;
    color: $medium-gray;

    &.saved {
      color: $brand-blue;
    }
  }
}

.title-box {
  width: 100%;
  padding: 0 10px;
  flex: 1;
}

.title {
  font-size: 16px;
  font-weight: 600;
}

.label,
.hint {
  font-size: 14px;
}

.hint {
  font-style: italic;
  color: $dark-gray;
}

.radio-button-group {
  min-width: 220px;
}
