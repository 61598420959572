@import '../../../../styles/variables';

.FarmfeedPost {
  position: relative;
  z-index: 1;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid $light-gray;
  padding: 0;
  margin: 15px 10px;
  background-color: $white;

  &.empty-message {
    .text-block {
      max-height: 40px;
    }
  }

  &.position-fixed {
    font-size: 14px;
    z-index: 15;

    &:hover {
      z-index: 15;
    }

    .post-content .text-block {
      flex: 1;
      margin: 0;
    }
  }

  .post-header {
    display: flex;
    align-items: center;
    background-color: $almost-white;
    border-bottom: 1px solid $light-gray;
    padding: 5px 10px;
    font-weight: 600;
    border-radius: 5px 5px 0 0;
  }

  .post-content {
    &.not-adding {
      &:hover {
        cursor: pointer;
      }
    }
    .avatar {
      display: flex;
      padding: 10px;
      align-items: center;
      flex-wrap: wrap;
      overflow-y: auto;

      .user-photo {
        width: 40px;
        height: 40px;
        align-self: flex-start;
        margin-right: 10px;
      }

      .user-name {
        font-size: 16px;
        font-weight: 600;
      }
    }

    .video-url-block {
      margin: 20px 10px;
    }

    .text-block {
      resize: none;
      padding: 10px 0 0 0;
      font-size: 14px;
      border: none;
      box-shadow: none;
      min-height: 40px;
      margin: 10px 0 0;

      &.not-adding {
        flex: 1;
        margin: 0;

        &:hover {
          cursor: pointer;
        }
      }

      &:focus {
        border: none;
        box-shadow: none;
      }
    }

    .mention-block {
      min-height: 100px;
      position: relative;

      .company-line {
        display: inline-block;
        margin-left: 15px;
        position: absolute;
        bottom: 25px;
        left: 0;

        a.company-mention {
          color: $primary;
        }
      }
    }

    .actions {
      .divider {
        margin: 0 15px 20px 15px;
      }

      .action-button {
        display: flex;
        flex-wrap: wrap;
        margin: 10px 10px 20px;

        @media screen and (max-width: 39.9375em){
          flex-direction: column;
          justify-content: center;

          &.align-start {
            .mention-btn:first-child {
              margin-right: 0;
            }
          }

          .mention-btn:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        .mention-btn {
          width: 100%;
          margin-bottom: 10px;
        }

        .mention-btn {
          &,
          &:link,
          &:visited {
            text-decoration: none;
            padding: 10px 10px;
            display: inline-block;
            border-radius: 50px;
            transition: all .2s;
            position: relative;
            font-size: 13px;
            background-color: #FAFAFA;
            box-shadow: rgba(0, 0, 0, 0.2) 0 2px 9px;
            font-weight: 600;

            border: none;
            cursor: pointer;
          }

          &:hover {
            transform: translateY(-1px);
            box-shadow: 0 4px 12px rgba(black,.2);
            color: #303030;
          }

          &:active,
          &:focus {
            outline: none;
            transform: translateY(-1px);
            box-shadow: 0 3px 10px rgba(black,.2);
          }

          &.disabled {
            background-color: $light-gray;
            cursor: not-allowed;
            color: $dark-gray;
            box-shadow: none;

            &:hover {
              transform: translateY(0);
              box-shadow: none;
            }

            &:active,
            &:focus {
              transform: translateY(0);
              box-shadow: none;
            }
          }

          &.active {
            background-color: $primary;
            color: $white;

            &:hover {
              transform: translateY(0);
            }
          }
        }

        .fa {
          font-size: 16px;
          margin-right: 5px;
        }
      }
      .activity-files {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: stretch;
        margin-bottom: 30px;
        padding: 0 5px;
      }

      .attach-file {
        color: $dark-gray;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background-color: $almost-white;
        border-top: 1px solid $medium-gray;

        .attach-btn {
          &:hover {
            cursor: pointer;
          }
        }

        .fa {
          font-size: 22px;
          margin-right: 5px;
        }
        .post-btn {
          flex: 0 0 60px;
          font-size: 14px;
          padding: 9px 12px;
        }
      }
    }
  }
}
