@charset "UTF-8";
.distynct-form {
  margin: 0 0 20px 60px; }
  @media screen and (max-width: 425px) {
    .distynct-form {
      margin: 0 0 20px 0; }
      .distynct-form .btn-item {
        width: 100%; }
        .distynct-form .btn-item:first-child {
          margin-right: 0;
          margin-bottom: 10px; } }
  .distynct-form .btn-item:first-child {
    margin-right: 10px; }

.box-group {
  display: flex;
  border: 2px solid #dddddd;
  border-radius: 3px; }
  .box-group .side-name {
    font-size: 13px;
    color: #8c8c8c;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    border-right: 1px solid #dddddd;
    width: 50px;
    justify-content: center;
    padding: 17px 0; }
    .box-group .side-name .rotated {
      writing-mode: vertical-lr;
      transform: scale(-1, -1);
      white-space: nowrap; }
  .box-group .box-body {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center; }

.disabled {
  opacity: 0.5;
  cursor: not-allowed; }

.nested-switсher {
  padding-left: 60px; }
