.templates {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;

  .primary-link {
    color: var(--color-primary);
  }

  .templates-panel-body {
    padding: 15px 20px;
  }

  .templates-panel {
    padding: 0 10px;
  }

  .templates-panel-title {
    padding: 15px 20px;
    min-height: 60px;

    h1 {
      font-size: 22px;
    }

    @media screen and (max-width: 63.9375em) {
      h1 {
        font-size: 18px;
      }
    }
  }
}
