.panel {
  margin: 0;
  width: 100%;
  background-color: transparent;

  >div {
    border: 1px solid var(--color-gray-light);

    &:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:not(:first-child) {
      border-top: none;
    }
  }

  .panel-heading {
    height: 50px;
    background-color: var(--color-white-bg);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 15px;

    h1 {
      font-weight: normal;
      font-size: 20px;
    }

    @media screen and (max-width: 63.9375em) {
      padding: 10px;
      flex-direction: column;
      height: auto;

      >*:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }

  .panel-control {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: 63.9375em) {
      width: 100%;
    }
  }

  .panel-body {
    background-color: var(--color-white);
    padding: 20px 10px;

    &.no-padding {
      padding: 0;
    }
  }
}